define('ember-href-to/helpers/href-to', ['exports', 'ember', 'ember-getowner-polyfill'], function (exports, _ember, _emberGetownerPolyfill) {
  'use strict';

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports['default'] = _ember['default'].Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref);

      var targetRouteName = _ref2[0];

      var rest = _ref2.slice(1);

      var router = (0, _emberGetownerPolyfill['default'])(this).lookup('router:main');
      if (router === undefined || router.router === undefined) {
        return;
      }

      var lastParam = rest[rest.length - 1];

      var queryParams = {};
      if (lastParam && lastParam.isQueryParams) {
        queryParams = rest.pop();
      }

      var args = [targetRouteName];
      args.push.apply(args, rest);
      args.push({ queryParams: queryParams.values });

      return router.generate.apply(router, args);
    }
  });
});