define('lacsso/mixins/throttled-resize', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    boundResize: null,
    throttleTimer: null,
    resizeInterval: 200,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('boundResize', this.triggerResize.bind(this));
      _ember['default'].$(window).on('resize', this.get('boundResize'));
      _ember['default'].$(window).on('focus', this.get('boundResize'));
      _ember['default'].run.next(this, 'onResize');
    },

    triggerResize: function triggerResize() {
      var timer = _ember['default'].run.throttle(this, 'onResize', this.get('resizeInterval'), false);
      this.set('throttleTimer', timer);
    },

    onResize: function onResize() {
      // Override me with resize logic
    },

    willDestroyElement: function willDestroyElement() {
      _ember['default'].run.cancel(this.get('throttleTimer'));
      _ember['default'].$(window).off('resize', this.get('boundResize'));
      _ember['default'].$(window).off('focus', this.get('boundResize'));
    }
  });
});