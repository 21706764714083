define("liquid-fire/helpers/lf-or", ["exports"], function (exports) {
  "use strict";

  exports.lfOr = lfOr;

  function lfOr(params /*, hash*/) {
    return params.reduce(function (a, b) {
      return a || b;
    }, false);
  }

  exports["default"] = Ember.Helper.helper(lfOr);
});