define('ember-api-store/utils/denormalize', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.denormalizeIdArray = denormalizeIdArray;
  exports.denormalizeId = denormalizeId;

  function denormalizeIdArray(field) {
    var type = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
    var storeName = arguments.length <= 2 || arguments[2] === undefined ? "store" : arguments[2];

    if (!type) {
      type = field.replace(/Ids$/, '');
    }

    var computed = _ember['default'].computed(field + '.[]', {
      get: function get(key) {
        var _this = this;

        var out = [];
        var store = this.get(storeName);
        (this.get(field) || []).forEach(function (id) {
          var obj = store.getById(type, id);
          if (obj) {
            out.push(obj);
          } else {
            store._missing(type, id, _this, key);
          }
        });

        return out;
      }
    });

    return computed;
  }

  function denormalizeId(field) {
    var type = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
    var storeName = arguments.length <= 2 || arguments[2] === undefined ? "store" : arguments[2];

    if (!type) {
      type = field.replace(/Id$/, '');
    }

    return _ember['default'].computed(field, {
      get: function get(key) {
        var id = this.get(field);
        var store = this.get(storeName);
        if (id) {
          return store.getById(type, id);
        } else {
          store._missing(type, id, this, key);
        }
      }
    });
  }
});