define('ember-intl/utils/is-equal', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = function (a, b) {
    if (!_ember['default'].isArray(a) || !_ember['default'].isArray(b)) {
      return false;
    }
    if (a === b) {
      return true;
    }

    return a.toString() === b.toString();
  };
});