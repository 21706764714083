define('ember-api-store/helpers/mock-store', ['exports', 'ember', 'ember-api-store/store', 'ember-api-store/models/collection', 'ember-api-store/models/resource'], function (exports, _ember, _emberApiStoreStore, _emberApiStoreModelsCollection, _emberApiStoreModelsResource) {
  'use strict';

  var _store = null;

  exports['default'] = function () {
    if (!_store) {
      var container = new _ember['default'].Container();
      container.register('model:collection', _emberApiStoreModelsCollection['default']);
      container.register('model:resource', _emberApiStoreModelsResource['default']);

      _store = _emberApiStoreStore['default'].create({
        container: container
      });
    }

    return _store;
  };
});