define("ember-intl/utils/array-to-hash", ["exports"], function (exports) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  "use strict";

  function arrayToHash(array) {
    var len = array.length;
    var out = Object.create(null);
    var i = 0;

    for (; i < len; i++) {
      var key = array[i];
      out[key] = key;
    }

    return out;
  }

  exports["default"] = arrayToHash;
});