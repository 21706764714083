define('ember-api-store/utils/ajax-promise', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.ajaxPromise = ajaxPromise;

  function ajaxPromise(opt, justBody) {
    var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
      _ember['default'].$.ajax(opt).then(success, fail);

      function success(body, textStatus, xhr) {
        if (justBody === true) {
          resolve(body, 'AJAX Response: ' + opt.url + '(' + xhr.status + ')');
        } else {
          resolve({ xhr: xhr, textStatus: textStatus }, 'AJAX Response: ' + opt.url + '(' + xhr.status + ')');
        }
      }

      function fail(xhr, textStatus, err) {
        reject({ xhr: xhr, textStatus: textStatus, err: err }, 'AJAX Error:' + opt.url + '(' + xhr.status + ')');
      }
    }, 'Raw AJAX Request: ' + opt.url);

    return promise;
  }

  exports['default'] = ajaxPromise;
});