define('ember-intl/formatters/format-date', ['exports', 'ember', 'intl-format-cache', 'ember-intl/formatters/-base'], function (exports, _ember, _intlFormatCache, _emberIntlFormattersBase) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;

  function assertIsDate(date, errMsg) {
    assert(errMsg, isFinite(date));
  }

  var FormatDate = _emberIntlFormattersBase['default'].extend({
    formatType: 'date',

    formatter: computed({
      get: function get() {
        return (0, _intlFormatCache['default'])(Intl.DateTimeFormat);
      }
    }).readOnly(),

    format: function format(value, options) {
      var ctx = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var dateTime = new Date(value);
      assertIsDate(dateTime, 'A date or timestamp must be provided to format-date');

      var formatOptions = this.filterSupporedOptions(options);

      return this._format(dateTime, formatOptions, null, ctx);
    }
  });

  FormatDate.reopenClass({
    supportedOptions: ['localeMatcher', 'timeZone', 'hour12', 'formatMatcher', 'weekday', 'era', 'year', 'month', 'day', 'hour', 'minute', 'second', 'timeZoneName']
  });

  exports['default'] = FormatDate;
});