define('ember-api-store/utils/fetch', ['exports', 'ember-network/fetch', 'ember'], function (exports, _emberNetworkFetch, _ember) {
  'use strict';

  exports.fetch = fetch;

  function fetch(url, opt) {
    opt = opt || {};
    if (!opt.credentials) {
      opt.credentials = 'same-origin';
    }

    if (opt.data && !opt.body) {
      opt.body = opt.data;
      delete opt.data;
    }

    return (0, _emberNetworkFetch['default'])(url, opt).then(done);
  }

  function done(res) {
    var ct = res.headers.get("content-type");
    if (res.status === 204) {
      return respond(res);
    } else if (ct && ct.toLowerCase().indexOf("application/json") >= 0) {
      return res.json().then(function (body) {
        return respond(res, body);
      });
    } else {
      return res.text().then(function (body) {
        return respond(res, body);
      });
    }
  }

  function respond(res, body) {
    var out = {
      body: body,
      status: res.status,
      statusText: res.statusText,
      headers: res.headers
    };

    if (res.ok) {
      return out;
    } else {
      return _ember['default'].RSVP.reject(out);
    }
  }

  exports['default'] = fetch;
});