define('ember-api-store/models/resource', ['exports', 'ember', 'ember-api-store/mixins/type'], function (exports, _ember, _emberApiStoreMixinsType) {
  'use strict';

  var Actionable = _ember['default'].Object.extend(_ember['default'].ActionHandler);
  var Resource = Actionable.extend(_emberApiStoreMixinsType['default'], {
    toString: function toString() {
      var str = 'resource:' + this.get('type');
      var id = this.get('id');
      if (id) {
        str += ':' + id;
      }

      return str;
    },

    serialize: function serialize() {
      var data = this._super.apply(this, arguments);
      if (this.constructor.mangleOut) {
        return this.constructor.mangleOut(data);
      } else {
        return data;
      }
    }
  });

  Resource.reopenClass({
    // You can provide an array of link names to always include when retrieving resources of this type
    defaultSortBy: '',

    // You can provide an array of link names to always include when retrieving resources of this type
    alwaysInclude: null,

    // You can provide a function here to mangle data before it is passed to store.createRecord() for purposes of evil.
    mangleIn: null,

    // You can provide a function here to mangle data after it is serialized for purposes of even more evil.
    mangleOut: null
  });

  exports['default'] = Resource;
});