define('ember-api-store/models/schema', ['exports', 'ember-api-store/models/resource', 'ember-api-store/utils/normalize'], function (exports, _emberApiStoreModelsResource, _emberApiStoreUtilsNormalize) {
  'use strict';

  var SCHEMA = {
    SIMPLE: ['string', 'password', 'multiline', 'float', 'int', 'date', 'blob', 'boolean', 'enum', 'reference', 'json']
  };

  exports.SCHEMA = SCHEMA;

  //  NESTED: ['array','map'],
  function parseType(type) {
    return type.replace(/]/g, '').split('[');
  }

  var Schema = _emberApiStoreModelsResource['default'].extend({
    getFieldNames: function getFieldNames() {
      return Object.keys(this.get('resourceFields'));
    },

    typeifyFields: (function () {
      // Schemas are special..
      if (this.get('id') === 'schema') {
        return [];
      }

      var fields = this.get('resourceFields');
      var keys = Object.keys(fields);

      var out = keys.filter(function (k) {
        var parts = parseType(fields[k].type);
        for (var i = 0; i < parts.length; i++) {
          if (SCHEMA.SIMPLE.includes(parts[i])) {
            return false;
          }
        }

        return true;
      });

      out.addObjects(this.get('includeableLinks') || []);

      return out;
    }).property(),

    getCreateDefaults: function getCreateDefaults(more) {
      var out = {};
      var fields = this.get('resourceFields');

      Object.keys(fields).forEach(function (key) {
        var field = fields[key];
        var def = field['default'];

        if (field.create && def !== null) {
          if (typeof def !== 'undefined') {
            out[key] = def;
          }
        }
      });

      if (more) {
        Object.keys(more).forEach(function (key) {
          out[key] = more[key];
        });
      }

      return out;
    },

    optionsFor: function optionsFor(field) {
      var obj = this.get('resourceFields')[field];
      if (obj && obj.options) {
        return (obj.options || []).slice();
      }

      return [];
    }
  });

  Schema.reopenClass({
    mangleIn: function mangleIn(data) {
      // Pass IDs through the type normalizer so they will match the case in other places like store.find('schema',normalizeType('thing'))
      data._id = data.id;
      data.id = (0, _emberApiStoreUtilsNormalize.normalizeType)(data.id);
      return data;
    }
  });

  exports['default'] = Schema;
});