define('lacsso/components/modal-base', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    modalService: _ember['default'].inject.service('modal'),
    modalOpts: _ember['default'].computed.alias('modalService.modalOpts'),
    // Focus does not want to focus on modal el here, dont know why but
    // esc wont work if a modal doesnt have a focused element
    //init() {
    //this._super(...arguments);
    //Ember.run.scheduleOnce('afterRender', ()=> {
    //console.log('Focused: ', this.$());
    //this.$().focus();
    //});
    //},
    keyUp: function keyUp(e) {
      if (e.which === 27 && this.escToClose()) {
        this.get('modalService').toggleModal();
      }
    },
    escToClose: function escToClose() {
      var modalService = this.get('modalService');
      if (modalService.get('modalVisible') && modalService.get('modalOpts.escToClose')) {
        return true;
      } else {
        return false;
      }
    },
    actions: {
      cancel: function cancel() {
        this.get('modalService').toggleModal();
      }
    }
  });
});