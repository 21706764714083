define('ember-api-store/utils/apply-headers', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.copyHeaders = copyHeaders;
  exports.applyHeaders = applyHeaders;

  // Copy the headers from `more` into the object `dest`
  // including ones with a value of undefined, so they
  // can be removed later by someone calling applyHeaders.

  function copyHeaders(more, dest) {
    if (!more || typeof more !== 'object') {
      return;
    }

    Object.keys(more).forEach(function (key) {
      var val = _ember['default'].get(more, key);
      var normalizedKey = key.toLowerCase();
      dest[normalizedKey] = val;
    });
  }

  // Apply the headers from `more` into the object `dest`

  function applyHeaders(more, dest, copyUndefined) {
    if (!more || typeof more !== 'object') {
      return;
    }

    Object.keys(more).forEach(function (key) {
      var val = _ember['default'].get(more, key);
      var normalizedKey = key.toLowerCase();
      if (val === undefined && copyUndefined !== true) {
        delete dest[normalizedKey];
      } else {
        dest[normalizedKey] = val;
      }
    });
  }
});