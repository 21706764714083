define('ember-api-store/mixins/serializable', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Serializable = _ember['default'].Mixin.create({
    serialize: function serialize(depth) {
      depth = depth || 0;
      var output;

      if (depth > 10) {
        return null;
      }

      if (_ember['default'].isArray(this)) {
        output = this.map(function (item) {
          return recurse(item, depth + 1);
        });
      } else {
        output = {};
        this.eachKeys(function (v, k) {
          output[k] = recurse(v, depth + 1);
        });
      }

      return output;

      function recurse(obj, depth) {
        depth = depth || 0;
        if (depth > 10) {
          return null;
        }

        if (_ember['default'].isArray(obj)) {
          return obj.map(function (item) {
            return recurse(item, depth + 1);
          });
        } else if (Serializable.detect(obj)) {
          return obj.serialize(depth + 1);
        } else if (obj && typeof obj === 'object') {
          var out = {};
          var keys = Object.keys(obj);
          keys.forEach(function (k) {
            out[k] = recurse(obj[k], depth + 1);
          });
          return out;
        } else {
          return obj;
        }
      }
    },

    // Properties to ignore because they're built-in to ember, ember-debug, or the store
    concatenatedProperties: ['reservedKeys'],
    reservedKeys: ['reservedKeys', 'includedKeys', '__nextSuper', 'constructor', 'container', 'store', 'isInstance', 'isDestroyed', 'isDestroying', 'concatenatedProperties', '_debugContainerKey', '_registry', 'cache', 'factoryCache', 'validationCache', 'store'],

    allKeys: function allKeys(withIncludes) {
      var _this = this;

      var reserved = this.get('reservedKeys');

      var alwaysIncluded = [];
      if (withIncludes === false) {
        alwaysIncluded = this.constructor.alwaysInclude || [];
      }

      var thisIncluded = this.get('includedKeys') || [];

      var out = Object.keys(this).filter(function (k) {
        return reserved.indexOf(k) === -1 && alwaysIncluded.indexOf(k) === -1 && thisIncluded.indexOf(k) === -1 && _ember['default'].typeOf(_ember['default'].get(_this, k)) !== 'function';
      });

      return out;
    },

    eachKeys: function eachKeys(fn, withIncludes) {
      var self = this;
      this.allKeys(withIncludes).forEach(function (k) {
        fn.call(self, self.get(k), k);
      });
    }
  });

  exports['default'] = Serializable;
});