define('ember-intl/formatters/format-time', ['exports', 'ember-intl/formatters/format-date'], function (exports, _emberIntlFormattersFormatDate) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  var FormatTime = _emberIntlFormattersFormatDate['default'].extend({
    formatType: 'time'
  });

  exports['default'] = FormatTime;
});