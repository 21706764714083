define('ember-intl/formatters/format-html-message', ['exports', 'ember', 'ember-intl/formatters/format-message'], function (exports, _ember, _emberIntlFormattersFormatMessage) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  var emberString = _ember['default'].String;
  var emberHandlebars = _ember['default'].Handlebars;

  var FormatHtmlMessage = _emberIntlFormattersFormatMessage['default'].extend({
    escapeProps: function escapeProps() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      return Object.keys(options).reduce(function (result, hashKey) {
        var value = options[hashKey];

        if (typeof value === 'string') {
          value = emberHandlebars.Utils.escapeExpression(value);
        }

        result[hashKey] = value;

        return result;
      }, {});
    },

    format: function format(value) {
      var formatOptions = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var options = this.escapeProps(formatOptions);
      var superResult = this._super(value, options, formatOptions.locale);

      return emberString.htmlSafe(superResult);
    }
  });

  exports['default'] = FormatHtmlMessage;
});