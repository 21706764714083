define('lacsso/mixins/sortable-base', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Mixin.create({
    sortableContent: _ember['default'].computed.alias('model'),
    headers: null,
    sortBy: null,
    descending: false,

    actions: {
      changeSort: function changeSort(name) {
        if (this.get('sortBy') === name) {
          this.set('descending', !this.get('descending'));
        } else {
          this.setProperties({
            descending: false,
            sortBy: name
          });
        }
      },

      // Like changeSort, but without the auto-flipping
      setSort: function setSort(name) {
        this.setProperties({
          descending: false,
          sortBy: name
        });
      }
    },

    currentSort: _ember['default'].computed('sortBy', 'headers.@each.{sortBy}', function () {
      var headers = this.get('headers');
      if (headers) {
        var header = headers.findBy('name', this.get('sortBy'));
        if (header) {
          var sort = get(header, 'sort');
          if (sort && sort.length) {
            return sort;
          }
        }
      }

      return ['id'];
    }),

    _sorted: _ember['default'].computed.sort('sortableContent', 'currentSort'),

    arranged: _ember['default'].computed('_sorted.[]', 'descending', function () {
      var out = this.get('_sorted');
      if (this.get('descending')) {
        return out.reverse();
      } else {
        return out;
      }
    })
  });
});