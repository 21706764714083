define('ember-api-store/index', ['exports', 'ember', 'ember-api-store/version'], function (exports, _ember, _emberApiStoreVersion) {
  'use strict';

  var EmberApiStore = _ember['default'].Namespace.create({
    VERSION: _emberApiStoreVersion['default']
  });

  if (_ember['default'].libraries) {
    _ember['default'].libraries.registerCoreLibrary('Ember API Store', EmberApiStore.VERSION);
  }

  exports['default'] = EmberApiStore;
});