define('lacsso/components/sortable-thead', ['exports', 'ember', 'lacsso/templates/components/sortable-thead'], function (exports, _ember, _lacssoTemplatesComponentsSortableThead) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _lacssoTemplatesComponentsSortableThead['default'],
    tagName: 'TH',
    classNames: ['sortable', 'lacsso'],
    name: null,
    sortable: null,
    width: null,
    label: null,
    ariaRole: ['columnheader'],
    isActions: false,

    current: _ember['default'].computed.alias('sortable.sortBy'),
    descending: _ember['default'].computed.alias('sortable.descending'),

    activeAscending: _ember['default'].computed('name', 'current', 'descending', function () {
      return !this.get('descending') && this.get('current') === this.get('name');
    }),

    activeDescending: _ember['default'].computed('name', 'current', 'descending', function () {
      return this.get('descending') && this.get('current') === this.get('name');
    }),

    attributeBindings: ['width'],

    click: function click() {
      this.sendAction('action', this.get('name'));
    }
  });
});