define('ember-api-store/helpers/mock-request', ['exports', 'ember', 'ember-api-store/helpers/mock-store'], function (exports, _ember, _emberApiStoreHelpersMockStore) {
  'use strict';

  exports['default'] = function (obj, data, isError, status) {
    var res = {
      textStatus: 'OK',
      xhr: {
        status: status || 200,
        responseText: typeof data === 'string' ? data : JSON.stringify(data),
        getResponseHeader: function getResponseHeader(name) {
          switch (name.toLowerCase()) {
            case 'content-type':
              return 'application/json';
          }
        }
      }
    };

    var store = obj.store = (0, _emberApiStoreHelpersMockStore['default'])();

    store.rawRequest = function () {
      if (isError) {
        return _ember['default'].RSVP.reject(res);
      } else {
        return _ember['default'].RSVP.resolve(res);
      }
    };
  };
});