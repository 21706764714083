define('ember-intl/helpers/format-date', ['exports', 'ember-intl/helpers/-format-base'], function (exports, _emberIntlHelpersFormatBase) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  exports['default'] = (0, _emberIntlHelpersFormatBase['default'])('date', {
    allowEmpty: true
  });
});