define('ember-api-store/initializers/store', ['exports', 'ember-api-store/services/store', 'ember-api-store/models/resource', 'ember-api-store/models/collection', 'ember-api-store/models/error', 'ember-api-store/models/schema'], function (exports, _emberApiStoreServicesStore, _emberApiStoreModelsResource, _emberApiStoreModelsCollection, _emberApiStoreModelsError, _emberApiStoreModelsSchema) {
  'use strict';

  exports['default'] = function () {
    var serviceName = arguments.length <= 0 || arguments[0] === undefined ? 'store' : arguments[0];
    var injectAs = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

    if (!injectAs) {
      injectAs = serviceName;
    }

    return function (app) {
      app.register('service:' + serviceName, _emberApiStoreServicesStore['default']);

      if (!app.hasRegistration('model:resource')) {
        app.register('model:resource', _emberApiStoreModelsResource['default']);
        app.register('model:collection', _emberApiStoreModelsCollection['default']);
        app.register('model:schema', _emberApiStoreModelsSchema['default']);
        app.register('model:error', _emberApiStoreModelsError['default']);
      }

      app.inject('controller', injectAs, 'service:' + serviceName);
      app.inject('route', injectAs, 'service:' + serviceName);
      app.inject('component', injectAs, 'service:' + serviceName);
    };
  };
});