define('ember-intl/utils/links', ['exports'], function (exports) {
  'use strict';

  var PROJECT_URL = 'https://github.com/jasonmit/ember-intl';

  exports['default'] = {
    unsetLocale: PROJECT_URL + '#setting-runtime-locale',
    asyncTranslations: PROJECT_URL + '/wiki/Asynchronously-loading-translations#asynchronous-loading-of-translations',
    polyfill: PROJECT_URL + '/wiki/Intl.js-Polyfill'
  };
});