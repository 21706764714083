define('lacsso/components/modal-root', ['exports', 'ember', 'lacsso/templates/components/modal-root'], function (exports, _ember, _lacssoTemplatesComponentsModalRoot) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _lacssoTemplatesComponentsModalRoot['default'],
    tagName: 'div',
    classNames: ['lacsso', 'modal-overlay'],
    classNameBindings: ['modalVisible:modal-open:modal-closed'],
    modalService: _ember['default'].inject.service('modal'),
    modalType: _ember['default'].computed.alias('modalService.modalType'),
    modalVisible: _ember['default'].computed.alias('modalService.modalVisible'),
    click: function click(e) {
      if (this.get('modalService.closeWithOutsideClick') && _ember['default'].$(e.target).hasClass('modal-open')) {
        this.get('modalService').toggleModal();
      }
    }
  });
});