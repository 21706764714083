define('ember-rl-dropdown/components/rl-dropdown-toggle', ['exports', 'ember', 'ember-rl-dropdown/components/rl-dropdown-container'], function (exports, _ember, _emberRlDropdownComponentsRlDropdownContainer) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['rl-dropdown-toggle'],

    tagName: 'button',

    attributeBindings: ['type'],

    type: _ember['default'].computed('tagName', function () {
      return this.get('tagName') === 'button' ? 'button' : null;
    }),

    dropdownContainer: _ember['default'].computed(function () {
      return this.nearestOfType(_emberRlDropdownComponentsRlDropdownContainer['default']);
    }),

    action: 'toggleDropdown',

    propagateClicks: true,

    click: function click(event) {
      var propagateClicks = this.get('propagateClicks');

      this.get('dropdownContainer').send(this.get('action'));

      if (propagateClicks === false || propagateClicks === 'false') {
        event.stopPropagation();
      }
    }
  });
});