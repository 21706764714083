define('ember-basic-dropdown/utils/calculate-position', ['exports', 'jquery'], function (exports, _jquery) {
  'use strict';

  exports.calculatePosition = calculatePosition;

  /**
    Function used to calculate the position of the content of the dropdown.
    @public
    @method calculatePosition
    @param {DomElement} trigger The trigger of the dropdown
    @param {DomElement} dropdown The content of the dropdown
    @param {Object} options The directives that define how the position is calculated
      - {String} horizantalPosition How the users want the dropdown to be positioned horizontally. Values: right | center | left
      - {String} verticalPosition How the users want the dropdown to be positioned vertically. Values: above | below
      - {Boolean} matchTriggerWidth If the user wants the width of the dropdown to match the width of the trigger
      - {String} previousHorizantalPosition How the dropdown was positioned for the last time. Same values than horizontalPosition, but can be null the first time.
      - {String} previousVerticalPosition How the dropdown was positioned for the last time. Same values than verticalPosition, but can be null the first time.
    @return {Object} How the component is going to be positioned.
      - {String} horizantalPosition The new horizontal position.
      - {String} verticalPosition The new vertical position.
      - {Object} CSS properties to be set on the dropdown. It supports `top`, `left`, `right` and `width`.
  */

  function calculatePosition(trigger, dropdown, _ref) {
    var previousHorizontalPosition = _ref.previousHorizontalPosition;
    var horizontalPosition = _ref.horizontalPosition;
    var previousVerticalPosition = _ref.previousVerticalPosition;
    var verticalPosition = _ref.verticalPosition;
    var matchTriggerWidth = _ref.matchTriggerWidth;

    var $window = (0, _jquery['default'])(self.window);
    var scroll = { left: $window.scrollLeft(), top: $window.scrollTop() };

    var _trigger$getBoundingClientRect = trigger.getBoundingClientRect();

    var triggerLeft = _trigger$getBoundingClientRect.left;
    var triggerTop = _trigger$getBoundingClientRect.top;
    var triggerWidth = _trigger$getBoundingClientRect.width;
    var triggerHeight = _trigger$getBoundingClientRect.height;

    var _dropdown$getBoundingClientRect = dropdown.getBoundingClientRect();

    var dropdownHeight = _dropdown$getBoundingClientRect.height;
    var dropdownWidth = _dropdown$getBoundingClientRect.width;

    var dropdownLeft = triggerLeft;
    var dropdownTop = undefined;
    dropdownWidth = matchTriggerWidth ? triggerWidth : dropdownWidth;

    var viewportRight = scroll.left + self.window.innerWidth;

    if (horizontalPosition === 'auto') {
      var roomForRight = viewportRight - triggerLeft;

      if (roomForRight < dropdownWidth) {
        horizontalPosition = 'right';
      } else if (triggerLeft < dropdownWidth) {
        horizontalPosition = 'left';
      } else {
        horizontalPosition = previousHorizontalPosition || 'left';
      }
    } else if (horizontalPosition === 'right') {
      dropdownLeft = triggerLeft + triggerWidth - dropdownWidth;
    } else if (horizontalPosition === 'center') {
      dropdownLeft = triggerLeft + (triggerWidth - dropdownWidth) / 2;
    }

    var triggerTopWithScroll = triggerTop + scroll.top;
    if (verticalPosition === 'above') {
      dropdownTop = triggerTopWithScroll - dropdownHeight;
    } else if (verticalPosition === 'below') {
      dropdownTop = triggerTopWithScroll + triggerHeight;
    } else {
      var viewportBottom = scroll.top + self.window.innerHeight;
      var enoughRoomBelow = triggerTopWithScroll + triggerHeight + dropdownHeight < viewportBottom;
      var enoughRoomAbove = triggerTop > dropdownHeight;

      if (previousVerticalPosition === 'below' && !enoughRoomBelow && enoughRoomAbove) {
        verticalPosition = 'above';
      } else if (previousVerticalPosition === 'above' && !enoughRoomAbove && enoughRoomBelow) {
        verticalPosition = 'below';
      } else if (!previousVerticalPosition) {
        verticalPosition = enoughRoomBelow ? 'below' : 'above';
      } else {
        verticalPosition = previousVerticalPosition;
      }
      dropdownTop = triggerTopWithScroll + (verticalPosition === 'below' ? triggerHeight : -dropdownHeight);
    }

    var style = { top: dropdownTop + 'px' };
    if (horizontalPosition === 'right') {
      style.right = viewportRight - (triggerWidth + triggerLeft) + 'px';
    } else {
      style.left = dropdownLeft + 'px';
    }
    if (matchTriggerWidth) {
      style.width = dropdownWidth + 'px';
    }

    return { horizontalPosition: horizontalPosition, verticalPosition: verticalPosition, style: style };
  }
});